<template>
  <div>
    <h3>{{ $t('reportsalary.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-card outlined rounded>
      <v-card-title>
        {{ $t('all.find') }}
        <v-menu
          v-model="mdte1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte1"
              :label="$t('all.from')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date1 = parseDate(dte1)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="mdte1 = false" locale="ru-ru"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="mdte2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte2"
              :label="$t('all.to')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date2 = parseDate(dte2)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="mdte2 = false" locale="ru-ru"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="dataLoad()">
          {{ $t('all.showreport') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h3>{{ $t('reportsalary.services') }}</h3>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('reportsalary.worker') }}</th>
              <th class="text-left">{{ $t('reportsalary.orders') }}</th>
              <th class="text-left">{{ $t('reportsalary.items') }}</th>
              <th class="text-left">{{ $t('reportsalary.service') }}</th>
              <th class="text-left">{{ $t('all.cost') }}</th>
              <th class="text-left">{{ $t('reportsalary.calc') }}</th>
              <th class="text-left">{{ $t('reportsalary.paid') }}</th>
              <th class="text-left">{{ $t('reportsalary.pay') }}</th>
              <th class="text-left">{{ $t('reportsalary.bonus') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableDataMaster2" :key="item.id" @click="openMaster(item.id)">
              <td>{{ item.master_fio }}</td>
              <td>{{ item.count_job }}</td>
              <td>{{ item.count_item }}</td>
              <td>{{ item.count_service }}</td>
              <td>{{ item.cost }}</td>
              <td>{{ item.salary_calc }}</td>
              <td>{{ item.salary_pay }}</td>
              <td>{{ item.salary_fact }}</td>
              <td>{{ item.salary_bonus }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-btn color="success" @click="save()">{{ $t('reportsalary.btnpay') }}</v-btn>
        <h3>Товары</h3>

        <v-treeview v-model="treeGood" :items="treeDataGood" activatable item-key="name" open-on-click>
          <template v-slot:append="{ item }">
            <div style="width: 150px">
              {{ item.pay }}<span v-if="item.bonus > 0" class="green--text">+{{ item.bonus }}</span>
            </div>
            <div style="width: 150px; text-align: right">
              <span v-if="item.type == 'good'" width="50px">
                <v-text-field
                  @keyup="updateData(item.id, item.cash)"
                  hide-details
                  height="20"
                  flat
                  v-model="item.cash"
                ></v-text-field>
              </span>
              <span v-else>
                {{ item.cash }}
              </span>
              <span v-if="item.type == 'good'">&nbsp;&nbsp;&nbsp;</span>
            </div>
          </template>
        </v-treeview>
        <v-btn color="success" @click="save()">{{ $t('reportsalary.btnpay') }}</v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="masterDialog">
      <v-card>
        <v-card-title class="headline">
          {{ $t('reportsalary.mastertitle', [masterTotal.master_fio]) }}
        </v-card-title>
        <v-card-text>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">{{ $t('all.job') }}</th>
                <th class="text-left">{{ $t('reportsalary.items') }}</th>
                <th class="text-left">{{ $t('reportsalary.service') }}</th>
                <th class="text-left">{{ $t('all.cost') }}</th>
                <th class="text-left">{{ $t('reportsalary.calc') }}</th>
                <th class="text-left">{{ $t('reportsalary.paid') }}</th>
                <th class="text-left">{{ $t('reportsalary.pay') }}</th>
                <th class="text-left">{{ $t('reportsalary.bonus') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in tableDataJob" :key="item.job" @click="openJob(masterTotal.master, item.job)">
                <td>{{ item.job_name }}</td>
                <td>{{ item.count_item }}</td>
                <td>{{ item.count_service }}</td>
                <td>{{ item.cost }}</td>
                <td>{{ item.salary_calc }}</td>
                <td>{{ item.salary_pay }}</td>
                <td>{{ item.salary_fact }}</td>
                <td>{{ item.salary_bonus }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="masterDialog = false"> {{ $t('all.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="jobDialog" scrollable>
      <v-card outlined rounded>
        <v-card-title class="headline">
          {{ $t('reportsalary.jobtitle', [jobTotal.job_name, jobTotal.master_fio]) }}
        </v-card-title>
        <v-card-text>
          <v-card outlined rounded v-for="item in tableDataService" :key="item.id">
            <v-card-title>{{ item.item_name }}</v-card-title>
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="text-left">{{ $t('reportsalary.services') }}</th>
                  <th class="text-left" width="50px">{{ $t('all.cost') }}</th>
                  <th class="text-left" width="50px">
                    {{ $t('reportsalary.calc') }}
                  </th>
                  <th class="text-left" width="50px">
                    {{ $t('reportsalary.paid') }}
                  </th>
                  <th class="text-left" width="50px">
                    {{ $t('reportsalary.pay') }}
                  </th>
                  <th class="text-left" width="50px">
                    {{ $t('reportsalary.bonus') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="sitem in item.services" :key="sitem.service">
                  <td>{{ sitem.service_name }}</td>
                  <td>{{ sitem.cost }}</td>
                  <td>{{ sitem.salary_calc }}</td>
                  <td>{{ sitem.salary_pay }}</td>
                  <td>
                    <v-text-field
                      @keyup="updateData(sitem.service, sitem.salary_fact)"
                      hide-details
                      height="20"
                      flat
                      v-model="sitem.salary_fact"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      @keyup="updateDataBonus(sitem.service, sitem.salary_bonus)"
                      hide-details
                      height="20"
                      flat
                      v-model="sitem.salary_bonus"
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="jobDialog = false"> {{ $t('all.close') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mdte1: false,
      mdte2: false,
      date1: null,
      date2: null,
      data: [],
      tree: [],
      treeGood: [],
      goodData: [],
      tableDataMaster: [],
      tableDataJob: [],
      tableDataService: [],
      masterDialog: false,
      jobDialog: false,
      jobTotal: [],
      masterTotal: [],
    }
  },
  computed: {
    dte1: function() {
      return this.formatDate(this.date1)
    },
    dte2: function() {
      return this.formatDate(this.date2)
    },
    smena: function() {
      return this.$store.state.auth.smena
    },
    tableDataMaster2: function() {
      var w = [],
        j = [],
        i = [],
        s = []
      this.data.forEach(function(e) {
        //                    console.log('e', e);
        //                    e.pay_bonus = -parseInt(e.salary_fact) + parseInt(e.salary_pay);
        if (w.length == 0 || w.find(w_ => w_.id === e.master) == undefined) {
          w.push({
            id: e.master,
            master_fio: e.master_fio,
            salary_calc: e.salary_calc,
            salary_fact: e.salary_fact,
            salary_pay: e.salary_pay,
            salary_bonus: e.salary_bonus,
            cost: e.cost,
            count_job: 1,
            count_service: 1,
            count_item: 1,
          })
        } else {
          var d = w.find(w_ => w_.id === e.master)
          d.salary_calc = parseInt(d.salary_calc) + parseInt(e.salary_calc)
          d.salary_fact = parseInt(d.salary_fact) + parseInt(e.salary_fact)
          d.salary_pay = parseInt(d.salary_pay) + parseInt(e.salary_pay)
          d.salary_bonus = parseInt(d.salary_bonus) + parseInt(e.salary_bonus)

          //                        console.log('find',j.find(j_ => j_ == e.job))
          if (j.find(j_ => j_ == e.master + '_' + e.job) == undefined) {
            console.log('d.count_job++', d.count_job)
            console.log('e.count_job ', e.job_name)
            d.count_job++
          }
          if (i.find(i_ => i_ == e.item) == undefined) {
            d.count_item++
          }
          if (s.find(s_ => s_ == e.service) == undefined) {
            d.count_service++
            d.cost = parseInt(d.cost) + parseInt(e.cost)
          }
        }
        j.push(e.master + '_' + e.job)
        i.push(e.item)
        s.push(e.service)
        //                    console.log('j',j);
      })
      //                console.log('finish');
      return w
    },
    //            treeData: function () {
    //                console.log('treeUpdate')
    //                console.log('this.data', JSON.parse(JSON.stringify(this.data)))
    //                var w = [], j = [], t = this, treeData = [];
    //                // первый проход, работы рассчетная сумма=рассчетная сумма-оплачено
    //                /* salary_calc -  расчетная зп - она будет сохранена
    //                 * pay_bonus - сколько начислено сверх расчетного
    //                 * salary_pay - фактическая выданная ЗП
    //                 * данные для отображения
    //                 * cash - начислить
    //                 * pay - фактическая выданная ЗП расчетная
    //                 * bonus - изменнение?
    //                 * */
    //                this.data.forEach(function (e) {
    //                    if (e.salary_calc == e.salary_fact) {
    //                        e.salary_calc = parseInt(e.salary_calc) - parseInt(e.salary_pay);
    //                        if (e.salary_calc < 0) {
    //                            e.salary_calc = 0;
    //                        }
    //
    //                    }
    //                    e.pay_bonus = -parseInt(e.salary_fact) + parseInt(e.salary_pay);
    ////                    console.log(e.salary_calc);
    ////                    if (parseInt(e.salary_pay)=>parseInt(e.salary_calc))
    //                    if (treeData.length == 0 || treeData.find(w_ => w_.id === e.master) == undefined) {
    //
    ////                        w.push({id: e.master, name: e.master_fio, cash: e.salary_calc, pay: e.salary_pay});
    //                        treeData.push({id: e.master, type: 'master', name: e.master_fio, cash: e.salary_calc, pay: e.salary_pay, bonus: e.pay_bonus, children: []});
    //                    } else {
    //                        var d = treeData.find(w_ => w_.id === e.master);
    //                        d.cash = parseInt(d.cash) + parseInt(e.salary_calc);
    //                        d.pay = parseInt(d.pay) + parseInt(e.salary_pay);
    //                        d.bonus = parseInt(d.bonus) + parseInt(e.pay_bonus);
    //                    }
    //                });
    //                this.data.forEach(function (e) {
    //                    j = treeData.find(w_ => w_.id === e.master).children;
    //                    if (j.length == 0 || j.find(w_ => w_.id === e.job) == undefined) {
    //
    //                        j.push({id: e.job, type: 'job', name: e.job_name, cash: e.salary_calc, pay: e.salary_pay, bonus: e.pay_bonus, children: []});
    //                    } else {
    //                        var d = j.find(w_ => w_.id === e.job);
    //                        d.cash = parseInt(d.cash) + parseInt(e.salary_calc);
    //                        d.pay = parseInt(d.pay) + parseInt(e.salary_pay);
    //                        d.bonus = parseInt(d.bonus) + parseInt(e.pay_bonus);
    //                    }
    //                });
    //                this.data.forEach(function (e) {
    //                    j = treeData.find(w_ => w_.id === e.master).children.find(j_ => j_.id === e.job).children;
    ////                    console.log('j',j);
    //                    if (j.length == 0 || j.find(w_ => w_.id === e.item) == undefined) {
    //
    //                        j.push({id: e.item, type: 'item', name: e.item_name, cash: e.salary_calc, pay: e.salary_pay, bonus: e.pay_bonus, children: []});
    //                    } else {
    //                        var d = j.find(w_ => w_.id === e.item);
    //                        d.cash = parseInt(d.cash) + parseInt(e.salary_calc);
    //                        d.pay = parseInt(d.pay) + parseInt(e.salary_pay);
    //                        d.bonus = parseInt(d.bonus) + parseInt(e.pay_bonus);
    //                    }
    //                });
    //                this.data.forEach(function (e) {
    //                    j = treeData.find(w_ => w_.id === e.master).children
    //                            .find(j_ => j_.id === e.job).children.find(j_ => j_.id === e.item).children;
    ////                    console.log('j',j);
    ////                    console.log('e.item',e.item);
    ////                    console.log('e.item',e.service);
    //                    if (j.length == 0 || j.find(w_ => w_.id === e.service) == undefined) {
    //
    //                        j.push({id: e.service, type: 'service', name: e.service_name, cash: e.salary_calc, pay: e.salary_pay, bonus: e.pay_bonus});
    //                    } else {
    //                        var d = j.find(w_ => w_.id === e.service);
    //                        d.cash = parseInt(d.cash) + parseInt(e.salary_calc);
    //                        d.pay = parseInt(d.pay) + parseInt(e.salary_pay);
    //                        d.bonus = parseInt(d.bonus) + parseInt(e.pay_bonus);
    //                    }
    //                });
    //                return treeData;
    //            },
    treeDataGood: function() {
      console.log('goodDatatreeUpdate')
      console.log('this.goodData', JSON.parse(JSON.stringify(this.goodData)))
      var j = [],
        treeData = []
      // первый проход, работы рассчетная сумма=рассчетная сумма-оплачено
      /* salary_calc -  расчетная зп - она будет сохранена
       * pay_bonus - сколько начислено сверх расчетного
       * salary_pay - фактическая выданная ЗП
       * данные для отображения
       * cash - начислить
       * pay - фактическая выданная ЗП расчетная
       * bonus - изменнение?
       * */
      this.goodData.forEach(function(e) {
        if (e.salary_calc == e.salary_fact) {
          e.salary_calc = parseInt(e.salary_calc) - parseInt(e.salary_pay)
          if (e.salary_calc < 0) {
            e.salary_calc = 0
          }
        }
        e.pay_bonus = -parseInt(e.salary_fact) + parseInt(e.salary_pay)
        //                    console.log(e.salary_calc);
        //                    if (parseInt(e.salary_pay)=>parseInt(e.salary_calc))
        if (treeData.length == 0 || treeData.find(w_ => w_.id === e.master) == undefined) {
          //                        w.push({id: e.master, name: e.master_fio, cash: e.salary_calc, pay: e.salary_pay});
          treeData.push({
            id: e.master,
            type: 'master',
            name: e.user_fio,
            cash: e.salary_calc,
            pay: e.salary_pay,
            bonus: e.pay_bonus,
            children: [],
          })
        } else {
          var d = treeData.find(w_ => w_.id === e.master)
          d.cash = parseInt(d.cash) + parseInt(e.salary_calc)
          d.pay = parseInt(d.pay) + parseInt(e.salary_pay)
          d.bonus = parseInt(d.bonus) + parseInt(e.pay_bonus)
        }
      })
      this.goodData.forEach(function(e) {
        j = treeData.find(w_ => w_.id === e.master).children
        if (j.length == 0 || j.find(w_ => w_.id === e.good) == undefined) {
          j.push({
            id: e.good,
            type: 'good',
            name: e.good_name + e.good,
            cash: e.salary_calc,
            pay: e.salary_pay,
            bonus: e.pay_bonus,
          })
        } else {
          var d = j.find(w_ => w_.id === e.good)
          d.cash = parseInt(d.cash) + parseInt(e.salary_calc)
          d.pay = parseInt(d.pay) + parseInt(e.salary_pay)
          d.bonus = parseInt(d.bonus) + parseInt(e.pay_bonus)
        }
      })
      return treeData
    },
  },
  components: {},
  mounted: function() {
    var dt = new Date()
    var month = dt.getMonth() + 1
    var day = dt.getDate()
    var year = dt.getFullYear()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    //                    this.dte2 = day + '.' + month + '.' + year;
    //                    this.dte1 = '01.' + month + '.' + year;
    this.date1 = year + '-' + month + '-' + '01'
    this.date2 = year + '-' + month + '-' + day
  },
  methods: {
    dteUpd1(v) {
      this.dte1 = v
    },
    dteUpd2(v) {
      this.dte2 = v
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateData: function(id, smm) {
      console.log('id', id)
      console.log('smm', smm)
      console.log('data', JSON.parse(JSON.stringify(this.data)))
      var r = this.data.find(w_ => w_.service === id)
      if (r.salary_calc < smm) {
        smm = r.salary_calc
      }
      this.$set(r, 'salary_fact', smm)
      console.log('data2', JSON.parse(JSON.stringify(this.data)))
      this.openMaster(r.master)
      this.openJob(r.master, r.job)
    },
    updateDataBonus: function(id, smm) {
      console.log('id', id)
      console.log('smm', smm)
      console.log('data', JSON.parse(JSON.stringify(this.data)))
      var r = this.data.find(w_ => w_.service === id)
      this.$set(r, 'salary_bonus', smm)
      console.log('data2', JSON.parse(JSON.stringify(this.data)))
      this.openMaster(r.master)
      this.openJob(r.master, r.job)
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'salary/calc', {
          dte1: t.dte1,
          dte2: t.dte2,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.data = JSON.parse(JSON.stringify(response.body.service))
            t.goodData = JSON.parse(JSON.stringify(response.body.good))
            //                            t.tableDataMaster = JSON.parse(JSON.stringify(response.body.worker));
            //                            t.tableDataJob = JSON.parse(JSON.stringify(response.body.job));
          },
          err => {
            console.log(err)
          },
        )
    },
    save: function() {
      var t = this
      console.log(t.data)
      var arr = Object.assign({}, t.data)
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'salary/savePay/', {
          data: arr,
          smena: t.smena.id,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              //                                    t.ijob = response.body.job;
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    openMaster: function(master) {
      var t = this,
        j = [],
        i = [],
        s = [],
        total = {}

      this.data.forEach(function(e) {
        //                    console.log('e', e);
        if (e.master == master) {
          total.master_fio = e.master_fio
          total.master = e.master
          total.cost += e.cost
          total.salary_calc += e.salary_calc
          total.salary_fact += e.salary_fact
          total.salary_pay += e.salary_pay
          total.salary_bonus += e.salary_bonus

          //                        e.pay_bonus = -parseInt(e.salary_fact) + parseInt(e.salary_pay);
          if (j.length == 0 || j.find(w_ => w_.job === e.job) == undefined) {
            j.push({
              job: e.job,
              job_name: e.job_name,
              salary_calc: e.salary_calc,
              salary_fact: e.salary_fact,
              salary_pay: e.salary_pay,
              salary_bonus: e.salary_bonus,
              cost: e.cost,
              count_service: 1,
              count_item: 1,
            })
          } else {
            var d = j.find(w_ => w_.job === e.job)
            d.salary_calc = parseInt(d.salary_calc) + parseInt(e.salary_calc)
            d.salary_fact = parseInt(d.salary_fact) + parseInt(e.salary_fact)

            d.salary_pay = parseInt(d.salary_pay) + parseInt(e.salary_pay)
            d.salary_bonus = parseInt(d.salary_bonus) + parseInt(e.salary_bonus)

            //                            console.log('d.count_service++', d.count_service);
            //                        console.log('find',j.find(j_ => j_ == e.job))
            if (i.find(i_ => i_ == e.item) == undefined) {
              d.count_item++
            }
            if (s.find(s_ => s_ == e.service) == undefined) {
              d.count_service++
              d.cost = parseInt(d.cost) + parseInt(e.cost)
            }
          }
        }

        i.push(e.item)
        s.push(e.service)
        //                    console.log('j',j);
      })
      //                console.log('j', j);
      t.tableDataJob = j
      t.masterDialog = true
      t.masterTotal = total
      return j
    },
    openJob: function(master, job) {
      var t = this,
        j = [],
        s = [],
        total = {}

      this.data.forEach(function(e) {
        if (e.job == job && e.master == master) {
          //                        console.log('e.salary_fact', e.salary_fact);
          //                        console.log('e.salary_calc', e.salary_calc);
          total.job_name = e.job_name
          total.master_fio = e.master_fio
          total.job = e.job
          //                        e.pay_bonus = -parseInt(e.salary_fact) + parseInt(e.salary_calc);
          if (j.length == 0 || j.find(w_ => w_.item === e.item) == undefined) {
            j.push({
              item: e.item,
              item_name: e.item_name,
              salary_calc: e.salary_calc,
              salary_fact: e.salary_fact,
              salary_pay: e.salary_pay,
              salary_bonus: e.salary_bonus,
              cost: e.cost,
              count_service: 1,
              services: [e],
            })
            //                            j.services.push(e);
          } else {
            var d = j.find(w_ => w_.item === e.item)
            d.salary_calc = parseInt(d.salary_calc) + parseInt(e.salary_calc)
            d.salary_fact = parseInt(d.salary_fact) + parseInt(e.salary_fact)

            d.salary_pay = parseInt(d.salary_pay) + parseInt(e.salary_pay)
            d.salary_bonus = parseInt(d.salary_bonus) + parseInt(e.salary_bonus)
            d.services.push(e)
            //                            console.log('d.count_service++', d.count_service);
            //                        console.log('find',j.find(j_ => j_ == e.job))
            if (s.find(s_ => s_ == e.service) == undefined) {
              d.count_service++
              d.cost = parseInt(d.cost) + parseInt(e.cost)
            }
          }
        }
        s.push(e.service)
        //                    console.log('j',j);
      })
      //                console.log('j', j);
      t.tableDataService = j
      t.jobDialog = true
      t.jobTotal = total
      return j
    },
  },
}
</script>

<style></style>
